export const features = {
  businessReviews: process.env.REACT_APP_FEATURE_DISPLAY_BUSINESS_REVIEWS,
  helpPanelMarkdownEditor: process.env.REACT_APP_FEATURE_DISPLAY_HELP_PANEL_MARKDOWN_EDITOR,
  aiSummaries: process.env.REACT_APP_FEATURE_AI_SUMMRIES,
  maintenanceMode: process.env.REACT_APP_FEATURE_MAINTENANCE_MODE,
  displayPermissions: process.env.REACT_APP_FEATURE_DISPLAY_PERMISSIONS_MANAGEMENT,
  commitmentsPermissions: process.env.REACT_APP_FEATURE_DISPLAY_COMMITMENTS_PERMISSIONS
};

export const isFeatureAvailable = (feature: keyof typeof features): boolean => features[feature] === 'true';
